<template>
  <footer class="footer"></footer>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "FooterComponent",
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      counterDomainType: this.config.counterDomainType,
    }
  }
})
</script>

<style lang="scss" src="./styles.scss"></style>
