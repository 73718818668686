<template>
  <ul class="social">
    <li class="social__item" v-for="(social, i) in socials" :key="i">
      <a
          :href="social.url"
          target="_blank"
          class="social__item-link"
      >
        <img
            :src="getImage(social.name)"
            class="social__item-image"
            :alt="social.name"
        />
      </a>
    </li>
  </ul>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "SocialsComponent",
  props: {
    socials: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getImage (name) {
      return require(`../../img/socials/${name}-logo.webp`);
    }
  }
})
</script>

<style lang="scss" src="./styles.scss"></style>
