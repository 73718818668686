export default {
  blogerName: 'RobbinLud',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@RobbinLud',
    },
    {
      name: 'youtube',
      url: 'https://rblud.site/youtuberbl',
    },
    {
      name: 'telegram',
      url: 'https://rblud.site/1000bezdep',
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://rbltop.site/RBLmonro',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://rbltop.site/RBLDR',
      gameTitle: 'Royal High-Road (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://rbltop.site/RBLSTAR',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://rbltop.site/RBLIZZ',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://rbltop.site/RBLLEG',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://rbltop.site/RBLSO',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://rbltop.site/RBLFRE',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://rbltop.site/RBLJE',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rbltop.site/RBLRX',
      gameTitle: 'Mechanical Clover (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://rbltop.site/RBLVOL',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>RBL</strong> и получи 100 фриспинов в следующих играх',
  yourPromocode: 'Ваш промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'RBL',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>100FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
};
